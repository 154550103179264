<template>
  <div>
    <h3 class="py-3">
      Filter by :
    </h3>
    <v-row>
      <v-col
        v-if="routeName != 'saveboxAction'"
        md="4"
      >
        <v-select
          v-model="form.status"
          :items="status"
          :label="$t('filter.status')"
          item-text="name"
          item-value="id"
          outlined
          dense
          @input="doFilter()"
        />
      </v-col>
      <v-col
        v-if="routeName != 'saveboxAction'"
        md="4"
      >
        <v-select
          v-model="form.payment_method"
          :items="payment_method"
          :label="$t('filter.paymentMethod')"
          item-text="name"
          item-value="id"
          outlined
          dense
          @input="doFilter()"
        />
      </v-col>
      <v-col md="4">
        <v-select
          v-model="form.branch_id"
          :items="branches"
          :label="$t('settings.branches')"
          item-text="name"
          item-value="id"
          outlined
          dense
          @input="doFilter()"
        />
      </v-col>
      <v-col
        v-if="routeName != 'saveboxAction'"
        md="4"
      >
        <v-autocomplete
          v-model="form.client_id"
          :items="items"
          :loading="isLoading"
          :search-input.sync="search"
          hide-no-data
          hide-selected
          outlined
          dense
          item-text="name"
          item-value="id"
          :label="$t('filter.clients')"
          :placeholder="$t('filter.startTypingToSearch')"
          @input="doFilter()"
        />
      </v-col>
      <v-col
        v-if="routeName != 'saveboxAction'"
        md="4"
      >
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.from_date"
              label="Date From"
              prepend-inner-icon="mdi-calendar"
              readonly
              dense
              outlined
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="form.from_date"
            @input="menu = false, doFilter()"
          />
        </v-menu>
      </v-col>
      <v-col
        v-if="routeName != 'saveboxAction'"
        md="4"
      >
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.to_date"
              label="Date To"
              prepend-inner-icon="mdi-calendar"
              readonly
              dense
              outlined
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="form.to_date"
            @input="menu2 = false, doFilter()"
          />
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { ServiceFactory } from '@/services/ServiceFactory'
  const branchesServices = ServiceFactory.get('branches')
  const clientsService = ServiceFactory.get('clients')

  export default {
    data: (vm) => ({
      descriptionLimit: 30,
      search: null,
      isLoading: false,
      entries: [],
      model: null,
      menu: false,
      menu2: false,
      branches: [],
      status: [
        {
          id: null,
          name: 'All',
        },
        {
          id: 'In',
          name: 'In',
        },
        {
          id: 'Out',
          name: 'Out',
        },
      ],
      clients: [],
      payment_method: [
        {
          id: null,
          name: 'All',
        }, {
          id: 1,
          name: 'Cash',
        }, {
          id: 2,
          name: 'Visa',
        }, {
          id: 3,
          name: 'Bank Check',
        },
      ],
      form: {
        branch_id: null,
        status: null,
        from_date: null,
        to_date: null,
        payment_method: null,
        client_id: null,
      },
      routeName: '',
    }),
    computed: {
      items () {
        return this.entries.map(entry => {
          const name = entry.name.length > this.descriptionLimit
            ? entry.name.slice(0, this.descriptionLimit) + '...'
            : entry.name
          return Object.assign({}, entry, { name })
        })
      },
    },

    watch: {
      async search (val) {
        // Items have already been loaded
        if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        const listData = await clientsService.getActiveList()
        console.log('listData.length', listData.length)
        this.entries = listData
        this.count = listData.length
        this.isLoading = false
      },
    },
    created () {
      this.routeName = this.$route.name
      this.fetchAllBranches()
    },
    methods: {
      doFilter () {
        console.log('this.form', this.form)
        this.$emit('doFilter', this.form)
      },
      async fetchAllBranches () {
        this.dataLoading = true
        const list = await branchesServices.getActiveList()
        this.branches = list.data
      },
    },
  }
</script>
